// src/components/Common/Banner.js

import React, { useEffect, useState } from 'react';
import dashboardApi from '../../api/dashboardApi';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../contexts/AuthContext';

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const { user } = useAuth();
  const [visibleBanners, setVisibleBanners] = useState([]);

  useEffect(() => {
    if (!user) return;
    const fetchBanners = async () => {
      try {
        const response = await dashboardApi.getDashboardData();
        const fetchedBanners = response.data.banners;

        // Optionally, filter banners based on user context
        // For simplicity, assume backend already provides relevant banners
        setBanners(fetchedBanners);
        setVisibleBanners(fetchedBanners);
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

    fetchBanners();
  }, []);

  const handleClose = (id) => {
    setVisibleBanners(prev => prev.filter(banner => banner.id !== id));
  };

  if (visibleBanners.length === 0) return null;

  return (
    <Box sx={{ mb: 2 }}>
      {visibleBanners.map(banner => (
        <Alert
          severity="info"
          key={banner.id}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => handleClose(banner.id)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <AlertTitle>Announcement</AlertTitle>
          {banner.content}
        </Alert>
      ))}
    </Box>
  );
};

export default Banner;
