// src/components/Layout.js

import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Banner from '../Common/Banner';
import { useAuth } from '../../contexts/AuthContext';

const Layout = ({ children }) => {
  const { user } = useAuth();
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Navbar />
      <Container component="main" sx={{ mt: 4, mb: 2, flexGrow: 1 }}>
      {user && <Banner />}
        {children}
      </Container>
      <Footer />
    </Box>
  );
};

export default Layout;
