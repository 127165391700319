// index.js

import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import SplashScreen from './SplashScreen';
import reportWebVitals from './reportWebVitals';
import { CustomThemeProvider } from './contexts/ThemeContext';
import CssBaseline from '@mui/material/CssBaseline';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { AuthProvider } from './contexts/AuthContext';
import { BrowserRouter as Router } from 'react-router-dom';


const paypalOptions = {
  'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: process.env.REACT_APP_PAYPAL_CURRENCY || 'USD',
};

const RootComponent = () => {
  const [showSplash, setShowSplash] = useState(() => {
    return !sessionStorage.getItem('splashShown');
  });

  const handleFinishSplash = () => {
    sessionStorage.setItem('splashShown', 'true');
    setShowSplash(false);
  };
  return (
    <React.StrictMode>
      <Router>
        <AuthProvider>
          <CustomThemeProvider>
            <CssBaseline />
            <PayPalScriptProvider options={paypalOptions}>
              {showSplash ? (
                <SplashScreen onFinish={handleFinishSplash} />
              ) : (
                <App />
              )}
            </PayPalScriptProvider>
          </CustomThemeProvider>
        </AuthProvider>
      </Router>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

reportWebVitals();
