// src/utils/auth.js

export const getAccessToken = () => {
    return localStorage.getItem('accessToken');
};

export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
};

export const setAccessToken = (token) => {
    localStorage.setItem('accessToken', token);
};

export const setRefreshToken = (token) => {
    localStorage.setItem('refreshToken', token);
};

export const clearTokens = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
};

export const logoutUser = () => {
    clearTokens();
    window.location.href = '/login'; // Redirect to login page
};
