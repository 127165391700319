// src/App.js

import React, { Suspense, lazy } from 'react';
import {  Routes, Route } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import ProtectedRoute from './routes/ProtectedRoute';

// Lazy load pages for better performance
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const PropertiesList = lazy(() => import('./pages/Properties/PropertiesList'));
const PropertyDetail = lazy(() => import('./pages/Properties/PropertyDetail'));
const PropertyForm = lazy(() => import('./pages/Properties/PropertyForm'));
const MaintenanceRequestsList = lazy(() => import('./pages/MaintenanceRequests/MaintenanceRequestsList'));
const MaintenanceRequestDetail = lazy(() => import('./pages/MaintenanceRequests/MaintenanceRequestDetail'));
const MaintenanceRequestCreate = lazy(() => import('./pages/MaintenanceRequests/MaintenanceRequestCreate'));
const OneTimePaymentPage = lazy(() => import('./pages/Payments/OneTimePaymentPage'));
const RecurringPaymentPage = lazy(() => import('./pages/Payments/RecurringPaymentPage'));
const PaymentHistoryPage = lazy(() => import('./pages/Payments/PaymentHistoryPage'));
const BannerCreate = lazy(() => import('./pages/Banners/BannerCreate'));
const BannerEdit = lazy(() => import('./pages/Banners/BannerEdit'));
const BannerList = lazy(() => import('./pages/Banners/BannerList'));
const EditProfile = lazy(() => import('./pages/EditProfile'));
const ChangePassword = lazy(() => import('./pages/ChangePassword'));



const App = () => {
  return (
          <Layout>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />

                {/* Protected Routes */}
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/properties"
                  element={
                    <ProtectedRoute>
                      <PropertiesList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/properties/create"
                  element={
                    <ProtectedRoute requiredRole="admin">
                      <PropertyForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/properties/:id"
                  element={
                    <ProtectedRoute>
                      <PropertyDetail />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/properties/:id/edit"
                  element={
                    <ProtectedRoute requiredRole="admin">
                      <PropertyForm />
                    </ProtectedRoute>
                  }
                />
                

                <Route
                  path="/maintenance-requests"
                  element={
                    <ProtectedRoute>
                      <MaintenanceRequestsList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/maintenance-requests/create"
                  element={
                    <ProtectedRoute requiredRole="tenant">
                      <MaintenanceRequestCreate />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/maintenance-requests/:id"
                  element={
                    <ProtectedRoute>
                      <MaintenanceRequestDetail />
                    </ProtectedRoute>
                  }
                />

                {/* Payment Routes */}
              <Route
                path="/payments/one-time"
                element={
                  <ProtectedRoute requiredRole="tenant">
                    <OneTimePaymentPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/payments/recurring"
                element={
                  <ProtectedRoute requiredRole="tenant">
                    <RecurringPaymentPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/payments/history"
                element={
                  <ProtectedRoute requiredRole="tenant">
                    <PaymentHistoryPage />
                  </ProtectedRoute>
                }
              />

              {/* Banner Creation Route */}
              <Route
                  path="/banners/create"
                  element={
                      <ProtectedRoute requiredRole="admin">
                          <BannerCreate />
                      </ProtectedRoute>
                  }
              />
              {/* Banner Edit Route */}
              <Route
                                path="/banners/edit/:id"
                                element={
                                    <ProtectedRoute requiredRole="admin">
                                        <BannerEdit />
                                    </ProtectedRoute>
                                }
                            />
              {/* Banner Manage Route */}
              <Route
                  path="/banners/manage"
                  element={
                      <ProtectedRoute requiredRole="admin">
                          <BannerList />
                      </ProtectedRoute>
                  }
              />
              <Route path="/settings/edit-profile" element={
                <ProtectedRoute> <EditProfile/></ProtectedRoute>
              }/>
              <Route path="/settings/change-password" element={
                <ProtectedRoute> <ChangePassword/></ProtectedRoute>
              }/>
                {/* Catch-all Route */}
                <Route path="*" element={<Dashboard />} />
              </Routes>
            </Suspense>
          </Layout>
      
  );
};

export default App;
