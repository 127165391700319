// src/contexts/AuthContext.js

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axiosInstance from '../api/axiosInstance'; // Ensure this is correctly configured
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

// Create the AuthContext
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};

// AuthProvider component to wrap around the app
export const AuthProvider = ({ children }) => {
    // State variables
    const [user, setUser] = useState(null);
    const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') || null);
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken') || null);
    const [loading, setLoading] = useState(true);

    // Timer ID for scheduled token refresh
    const [refreshTimerId, setRefreshTimerId] = useState(null);

    const navigate = useNavigate(); // For programmatic navigation

    /**
     * Utility function to decode JWT and get expiry time in milliseconds
     * @param {string} token - JWT access token
     * @returns {number|null} - Expiry time in ms or null if invalid
     */
    const getTokenExpiry = (token) => {
        try {
            const decoded = jwtDecode(token);
            if (decoded && decoded.exp) {
                return decoded.exp * 1000; // Convert to milliseconds
            }
            return null;
        } catch (error) {
            console.error('Failed to decode token:', error);
            return null;
        }
    };

    /**
     * Function to refresh the access token using the refresh token
     * @returns {string|null} - New access token or null if refresh fails
     */
    const refreshAccessToken = useCallback(async () => {
        if (!refreshToken) {
            logout(); // If no refresh token, logout the user
            return null;
        }
        try {
            const response = await axiosInstance.post('/token/refresh/', {
                refresh: refreshToken,
            });

            const { access } = response.data;

            // Update tokens in state and localStorage
            setAccessToken(access);
            localStorage.setItem('accessToken', access);

            // Schedule the next token refresh
            scheduleTokenRefresh(access);

            return access;
        } catch (error) {
            console.error('Refresh token error:', error);
            logout(); // If refresh fails, logout the user
            return null;
        }
    }, [refreshToken]);

    /**
     * Function to schedule token refresh before it expires
     * @param {string} access - Current access token
     */
    const scheduleTokenRefresh = useCallback((access) => {
        const expiryTime = getTokenExpiry(access);
        if (!expiryTime) {
            console.warn('Cannot schedule token refresh, invalid access token.');
            return;
        }

        const currentTime = Date.now();
        const timeout = expiryTime - currentTime - (60 * 1000); // Refresh 1 minute before expiry

        if (timeout <= 0) {
            // Token already expired or about to expire
            refreshAccessToken();
            return;
        }

        // Clear any existing timer
        if (refreshTimerId) {
            clearTimeout(refreshTimerId);
        }

        const id = setTimeout(() => {
            refreshAccessToken();
        }, timeout);

        setRefreshTimerId(id);
    }, [refreshAccessToken, refreshTimerId]);

    /**
     * Function to log in the user
     * @param {string} username
     * @param {string} password
     * @returns {Object} - { success: boolean, message?: string }
     */
    const login = async (username, password) => {
        try {
            const response = await axiosInstance.post('/token/', {
                username,
                password,
            });

            const { access, refresh } = response.data;

            // Store tokens in localStorage
            localStorage.setItem('accessToken', access);
            localStorage.setItem('refreshToken', refresh);

            setAccessToken(access);
            setRefreshToken(refresh);

            // Fetch user data
            const userResponse = await axiosInstance.get('/accounts/me/', {
                headers: {
                    Authorization: `Bearer ${access}`,
                },
            });

            setUser(userResponse.data);
            localStorage.setItem('user', JSON.stringify(userResponse.data));

            // Schedule token refresh
            scheduleTokenRefresh(access);

            return { success: true };
        } catch (error) {
            console.error('Login error:', error);
            return { success: false, message: error.response?.data?.detail || 'Login failed' };
        }
    };

    /**
     * Function to register a new user
     * @param {Object} userData - { username, password, ... }
     * @returns {Object} - { success: boolean, data?: Object, message?: string }
     */
    const register = async (userData) => {
        try {
            const response = await axiosInstance.post('/accounts/register/', userData);
            return { success: true, data: response.data };
        } catch (error) {
            console.error('Registration error:', error);
            return { success: false, message: error.response?.data?.detail || 'Registration failed' };
        }
    };

    /**
     * Function to log out the user
     */
    const logout = useCallback(async () => {
        try {
            if (refreshToken) {
                await axiosInstance.post(
                    '/accounts/logout/',
                    { refresh: refreshToken },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
            }
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            setUser(null);
            setAccessToken(null);
            setRefreshToken(null);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('user');

            // Clear any existing token refresh timers
            if (refreshTimerId) {
                clearTimeout(refreshTimerId);
                setRefreshTimerId(null);
            }

            // Redirect to login page after logout
            navigate('/login');
        }
    }, [accessToken, refreshToken, navigate, refreshTimerId]);

    /**
     * Effect to fetch user data if tokens are available on initial load
     */
    useEffect(() => {
        const fetchUser = async () => {
            if (accessToken) {
                try {
                    const userResponse = await axiosInstance.get('/accounts/me/', {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    setUser(userResponse.data);
                    localStorage.setItem('user', JSON.stringify(userResponse.data));

                    // Schedule token refresh
                    scheduleTokenRefresh(accessToken);
                } catch (error) {
                    console.error('Fetch user error:', error);
                    // Attempt to refresh token
                    const newAccessToken = await refreshAccessToken();
                    if (newAccessToken) {
                        try {
                            const userResponse = await axiosInstance.get('/accounts/me/', {
                                headers: {
                                    Authorization: `Bearer ${newAccessToken}`,
                                },
                            });
                            setUser(userResponse.data);
                            localStorage.setItem('user', JSON.stringify(userResponse.data));

                            // Schedule token refresh
                            scheduleTokenRefresh(newAccessToken);
                        } catch (err) {
                            console.error('Fetch user after refresh error:', err);
                            logout();
                        }
                    }
                }
            }
            setLoading(false);
        };

        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Effect to set up Axios interceptors
     */
    useEffect(() => {
        // List of endpoints to exclude from token refresh
        const excludedEndpoints = [
            '/api/token/',              // Token obtain and refresh
            '/api/token/refresh/',
            '/api/accounts/register/',  // User registration
            '/api/accounts/logout/',    // User logout
            // Add other public endpoints if necessary
        ];

        // Request interceptor to add access token to headers
        const requestInterceptor = axiosInstance.interceptors.request.use(
            (config) => {
                if (accessToken) {
                    config.headers['Authorization'] = `Bearer ${accessToken}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        // Response interceptor to handle 401 errors and attempt token refresh
        const responseInterceptor = axiosInstance.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;

                // Prevent infinite loops by checking if request is already retried
                if (error.response?.status === 401 && !originalRequest._retry) {
                    // Check if the request URL is excluded
                    const isExcluded = excludedEndpoints.some(endpoint => originalRequest.url.includes(endpoint));

                    if (isExcluded) {
                        // If the endpoint is excluded, do not attempt to refresh token
                        return Promise.reject(error);
                    }

                    originalRequest._retry = true;
                    const newAccessToken = await refreshAccessToken();
                    if (newAccessToken) {
                        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                        return axiosInstance(originalRequest);
                    }
                }

                return Promise.reject(error);
            }
        );

        // Cleanup interceptors on unmount
        return () => {
            axiosInstance.interceptors.request.eject(requestInterceptor);
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };
    }, [accessToken, refreshAccessToken]);

    /**
     * Value provided by the AuthContext
     */
    const value = {
        user,
        accessToken,
        refreshToken,
        login,
        register,
        logout,
        refreshAccessToken,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
