// src/components/Navbar.js

import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { useAuth } from '../../contexts/AuthContext';
import { useThemeContext } from '../../contexts/ThemeContext';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ApartmentIcon from '@mui/icons-material/Apartment'; // Properties Icon
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual'; // Banners Icon

const Navbar = () => {
  const { user, logout } = useAuth();
  const { mode, toggleTheme } = useThemeContext();
  const [drawerOpen, setDrawerOpen] = useState(false);

  // State for Settings dropdown menu in desktop
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const openSettingsMenu = Boolean(anchorElSettings);
  const handleSettingsClick = (event) => {
    setAnchorElSettings(event.currentTarget);
  };
  const handleSettingsClose = () => {
    setAnchorElSettings(null);
  };

  // State for Properties dropdown menu in desktop
  const [anchorElProperties, setAnchorElProperties] = useState(null);
  const openPropertiesMenu = Boolean(anchorElProperties);
  const handlePropertiesClick = (event) => {
    setAnchorElProperties(event.currentTarget);
  };
  const handlePropertiesClose = () => {
    setAnchorElProperties(null);
  };

  // State for Banners dropdown menu in desktop
  const [anchorElBanners, setAnchorElBanners] = useState(null);
  const openBannersMenu = Boolean(anchorElBanners);
  const handleBannersClick = (event) => {
    setAnchorElBanners(event.currentTarget);
  };
  const handleBannersClose = () => {
    setAnchorElBanners(null);
  };

  // State for collapsible menus in mobile drawer
  const [openPropertiesMobile, setOpenPropertiesMobile] = useState(false);
  const [openBannersMobile, setOpenBannersMobile] = useState(false);

  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const toggleDrawerOpen = (open) => () => {
    setDrawerOpen(open);
  };

  // Define menu items based on user and role
  // Dashboard is always shown to authenticated users
  // Login is shown to unauthenticated users
  const menuItems = user
    ? [
        { text: 'Dashboard', link: '/dashboard', icon: <AccountCircle /> },
        { text: 'Maintenance', link: '/maintenance-requests', icon: <ApartmentIcon /> },
      ]
    : [
        { text: 'Login', link: '/login', icon: <AccountCircle /> },
        { text: 'Register', link: '/register', icon: <AccountCircle /> },
      ];

  // Properties menu items
  const propertiesMenuItems = [
    { text: 'Properties List', link: '/properties', icon: <ApartmentIcon /> },
    user && user.role === 'admin'
      ? { text: 'Register Property', link: '/properties/create', icon: <AccountCircle /> }
      : null,
  ].filter(Boolean);

  // Banners menu items (admin only)
  const bannersMenuItems =
    user && user.role === 'admin'
      ? [
          { text: 'Create Banner', link: '/banners/create', icon: <PhotoSizeSelectActualIcon /> },
          { text: 'Manage Banners', link: '/banners/manage', icon: <PhotoSizeSelectActualIcon /> },
        ]
      : [];

  // Settings menu items
  const settingsMenuItems = [
    { text: 'Edit Profile', link: '/settings/edit-profile', icon: <AccountCircle /> },
    { text: 'Change Password', link: '/settings/change-password', icon: <AccountCircle /> },
    { text: 'Dark Mode', toggle: true },
  ];

  // Last menu items (Logout)
  const lastMenuItems = user ? [{ text: 'Logout', action: handleLogout, icon: <AccountCircle /> }] : [];

  return (
    <>
      <AppBar
        position="static"
        sx={{
          minHeight: '80px',
          justifyContent: 'center',
          background: 'linear-gradient(to top,#87CEEB, #C8E2EB)',
        }}
      >
        <Toolbar>
          {/* Mobile Menu Button */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { sm: 'none' } }}
            onClick={toggleDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          {/* Branding or Logo */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link component={RouterLink} to="/" color="inherit" underline="none">
              Rental Portal
            </Link>
          </Typography>

          {/* Desktop Navigation Links */}
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
            {/* Dashboard / Login / Register */}
            {menuItems.map((item, index) => (
              <Button key={index} color="inherit" component={RouterLink} to={item.link} startIcon={item.icon}>
                {item.text}
              </Button>
            ))}

            {/* Properties Menu */}
            {user && (
              <>
                <Button color="inherit" onClick={handlePropertiesClick} startIcon={<ApartmentIcon />}>
                  Properties
                </Button>
                <Menu
                  anchorEl={anchorElProperties}
                  open={openPropertiesMenu}
                  onClose={handlePropertiesClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  {propertiesMenuItems.map((item, index) => (
                    <MenuItem key={index} component={RouterLink} to={item.link} onClick={handlePropertiesClose}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}

            {/* Banners Menu (Admin Only) */}
            {user && user.role === 'admin' && (
              <>
                <Button color="inherit" onClick={handleBannersClick} startIcon={<PhotoSizeSelectActualIcon />}>
                  Banners
                </Button>
                <Menu
                  anchorEl={anchorElBanners}
                  open={openBannersMenu}
                  onClose={handleBannersClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  {bannersMenuItems.map((item, index) => (
                    <MenuItem key={index} component={RouterLink} to={item.link} onClick={handleBannersClose}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}

            {/* Payments Link */}
            {user && (
              <Button
                color="inherit"
                component={RouterLink}
                to="/payments/one-time"
                startIcon={<AccountCircle />}
              >
                Payments
              </Button>
            )}

            {/* Settings Dropdown Menu */}
            {user && (
              <>
                <Button color="inherit" onClick={handleSettingsClick} startIcon={<SettingsIcon />}>
                  Settings
                </Button>
                <Menu
                  anchorEl={anchorElSettings}
                  open={openSettingsMenu}
                  onClose={handleSettingsClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  {settingsMenuItems.map((item, index) =>
                    item.toggle ? (
                      <MenuItem key={index}>
                        <ListItemIcon>
                          {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </ListItemIcon>
                        <ListItemText primary={item.text} />
                        <Switch
                          checked={mode === 'dark'}
                          onChange={toggleTheme}
                          inputProps={{ 'aria-label': 'Dark mode toggle' }}
                        />
                      </MenuItem>
                    ) : (
                      <MenuItem
                        key={index}
                        component={RouterLink}
                        to={item.link}
                        onClick={handleSettingsClose}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                      </MenuItem>
                    )
                  )}
                </Menu>
              </>
            )}

            {/* Logout Button */}
            {lastMenuItems.map((item, index) =>
              item.link ? (
                <Button key={index} color="inherit" component={RouterLink} to={item.link} startIcon={item.icon}>
                  {item.text}
                </Button>
              ) : (
                <Button key={index} color="inherit" onClick={item.action} startIcon={item.icon}>
                  {item.text}
                </Button>
              )
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawerOpen(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawerOpen(false)}
          onKeyDown={toggleDrawerOpen(false)}
        >
          <List>
            {/* Dashboard / Login / Register */}
            {menuItems.map((item, index) => (
              <ListItemButton key={index} component={RouterLink} to={item.link}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            ))}

            {/* Properties Collapsible */}
            {user && (
              <>
                <ListItemButton onClick={() => setOpenPropertiesMobile(!openPropertiesMobile)}>
                  <ListItemIcon>
                    <ApartmentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Properties" />
                  {openPropertiesMobile ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openPropertiesMobile} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {propertiesMenuItems.map((item, index) => (
                      <ListItemButton
                        key={index}
                        component={RouterLink}
                        to={item.link}
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </>
            )}

            {/* Banners Collapsible (Admin Only) */}
            {user && user.role === 'admin' && (
              <>
                <ListItemButton onClick={() => setOpenBannersMobile(!openBannersMobile)}>
                  <ListItemIcon>
                    <PhotoSizeSelectActualIcon />
                  </ListItemIcon>
                  <ListItemText primary="Banners" />
                  {openBannersMobile ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openBannersMobile} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {bannersMenuItems.map((item, index) => (
                      <ListItemButton
                        key={index}
                        component={RouterLink}
                        to={item.link}
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </>
            )}

            {/* Payments Link */}
            {user && (
              <ListItemButton component={RouterLink} to="/payments/one-time">
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary="Payments" />
              </ListItemButton>
            )}

            {/* Settings */}
            {user && (
              <>
                <ListItemButton component={RouterLink} to="/settings/edit-profile">
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  <ListItemText primary="Edit Profile" />
                </ListItemButton>
                <ListItemButton component={RouterLink} to="/settings/change-password">
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  <ListItemText primary="Change Password" />
                </ListItemButton>
                <ListItemButton onClick={toggleTheme}>
                  <ListItemIcon>
                    {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                  </ListItemIcon>
                  <ListItemText primary="Dark Mode" />
                  <Switch
                    checked={mode === 'dark'}
                    onChange={toggleTheme}
                    inputProps={{ 'aria-label': 'Dark mode toggle' }}
                  />
                </ListItemButton>
              </>
            )}

            {/* Logout */}
            {lastMenuItems.map((item, index) => (
              <ListItemButton
                key={index}
                component={item.link ? RouterLink : 'button'}
                to={item.link ? item.link : undefined}
                onClick={item.action ? item.action : undefined}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;
