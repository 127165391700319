// src/api/dashboardApi.js

import axiosInstance from './axiosInstance';

/**
 * Fetch dashboard data for the authenticated user.
 * @returns {Promise<Object>} - Returns dashboard data including upcoming rent, payment history, maintenance requests, and banners.
 */
 const getDashboardData = () => {
  return axiosInstance.get('/dashboard/');
};

/**
 * Fetch all active banners (for admin).
 * @returns {Promise<Array>} - Returns an array of banners.
 */
 const getBanners = () => {
    return axiosInstance.get('/banners/');
  };
  
  /**
   * Create a new banner (admin).
   * @param {Object} bannerData - Data for the new banner.
   * @returns {Promise<Object>} - Returns the created banner.
   */
   const createBanner = (bannerData) => {
    return axiosInstance.post('/banners/', bannerData);
  };


/**
 * Fetch a single banner by ID
 * @param {number} id - Banner ID
 * @returns {Promise<Object>} - Banner data
 */
 const getBannerById = async (id) => {
    try {
      const response = await axiosInstance.get(`/banners/${id}/`);
      return response.data;
    } catch (error) {
      throw error.response || error;
    }
  };
  
  
  /**
   * Update an existing banner (admin).
   * @param {number} id - Banner ID.
   * @param {Object} bannerData - Updated data.
   * @returns {Promise<Object>} - Returns the updated banner.
   */
   const updateBanner = (id, bannerData) => {
    return axiosInstance.put(`/banners/${id}/`, bannerData);
  };
  
  /**
   * Delete a banner (admin).
   * @param {number} id - Banner ID.
   * @returns {Promise} - Returns the response.
   */
   const deleteBanner = (id) => {
    return axiosInstance.delete(`/banners/${id}/`);
  };

  /**
 * Fetch all tenants (for dropdown selection).
 * @returns {Promise<Array>} - Returns an array of tenant users.
 */
const getTenants = () => {
    return axiosInstance.get('/dashboard/tenants/');
  };

  const dashboardApi = {
    getDashboardData,
    getBanners,
    createBanner,
    updateBanner,
    deleteBanner,
    getBannerById,
    getTenants,
  };

  export default dashboardApi;