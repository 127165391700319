import React, { useEffect, useRef, useState } from 'react';

const SplashScreen = ({ onFinish }) => {
  const videoRef = useRef(null);
  const [showEnterButton, setShowEnterButton] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const mainVideo = videoRef.current;

    // Show the enter button when the main video ends
    const handleVideoEnd = () => {
      setShowEnterButton(true);
    };

    // Add event listener to the main video
    mainVideo.addEventListener('ended', handleVideoEnd);

    // Clean up the event listener
    return () => {
      mainVideo.removeEventListener('ended', handleVideoEnd);
    };
  }, []);

  const handleFinish = () => {
    setFadeOut(true);
    setTimeout(() => {
      onFinish();
    }, 1000); // Duration should match the CSS transition
  };

  return (
    <div style={{ ...styles.container, ...(fadeOut ? styles.fadeOut : {}) }}>
      {/* Background Image */}
      <div style={styles.backgroundImage} />

      {/* Overlay to Blend Elements */}
      <div style={styles.leftOverlay} />
      <div style={styles.rightOverlay} />
      <div style={styles.shadingOverlay} />

      {/* Main Video */}
      <video
        ref={videoRef}
        style={styles.mainVideo}
        src="/blpsplash.mp4"
        type="video/mp4"
        autoPlay
        playsInline
        muted
        poster="/splashposter.png"
      >
        Your browser does not support the video tag.
      </video>

      {/* Skip Button */}
      {!showEnterButton && (
        <button
          style={styles.skipButton}
          onClick={handleFinish}
          aria-label="Skip Splash Screen"
        >
          Skip
        </button>
      )}

      {/* Click Overlay */}
      {showEnterButton && (
        <div
          style={styles.clickOverlay}
          onClick={handleFinish}
          role="button"
          tabIndex={0}
          aria-label="Enter Application"
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') handleFinish();
          }}
        />
      )}
    </div>
  );
};

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    zIndex: 9999,
    transition: 'opacity 1s ease-out',
    opacity: 1,
  },
  fadeOut: {
    opacity: 0,
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: 'url(/background.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    zIndex: 0,
  },
// Left Overlay with Four Gradient Quarters
leftOverlay: {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '50%',
  height: '100%',
  background: 'linear-gradient(to bottom, rgba(230, 240, 255, 0.08) 15%, rgba(180, 200, 230, 0.10) 30%, rgba(140, 170, 210, 0.12) 75%, rgba(100, 130, 180, 0.14) 100%)', // 4 quarters to adjust the tone.
  mixBlendMode: 'multiply',
  zIndex: 1,
},

// Right Overlay with Four Gradient Quarters
rightOverlay: {
  position: 'absolute',
  top: 0,
  right: 0,
  width: '50%',
  height: '100%',
  background: 'linear-gradient(to bottom, rgba(200, 220, 255, 0.09) 15%, rgba(150, 190, 240, 0.12) 30%, rgba(140, 180, 200, 0.11) 75%, rgba(110, 170, 160, 0.10) 100%)', // Adjusted to reflect gradual transitions.
  mixBlendMode: 'multiply',
  zIndex: 1,
},
// Shading Overlay to Darken the Whole Scene
shadingOverlay: {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.02) 0%,rgba(0, 0, 0, 0.01) 50%, rgba(0, 0, 0, 0.00) 100%)', // Uniform shading
  mixBlendMode: 'multiply',
  zIndex: 2,
},

  mainVideo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    height: '100%',
    width: 'auto',
    transform: 'translate(-50%, -50%)',
    objectFit: 'contain',
    zIndex: 2,
  },
  clickOverlay: {
    position: 'absolute',
    bottom: '10%',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '10vw',
    height: '30vh',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    zIndex: 3,
  },
  skipButton: {
    position: 'absolute',
    top: '10%',
    right: '5%',
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#ffffff88',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    zIndex: 3,
  },
};

export default SplashScreen;
