// src/theme.js

import { createTheme } from '@mui/material/styles';

const theme = (mode) =>
    createTheme({
        palette: {
            mode,
            primary: {
            main: '#1976d2', // Customize your primary color
            },
            secondary: {
            main: '#dc004e', // Customize your secondary color
            },
            background: {
            default: '#f5f5f5', // Customize your background color
            },
        },
        typography: {
            fontFamily: 'Roboto, Arial, sans-serif',
        },
        components: {
            MuiButton: {
            styleOverrides: {
                containedPrimary: {
                backgroundColor: '#1976d2',
                '&:hover': {
                    backgroundColor: '#115293',
                },
                },
            },
            },
        },
    });

export default theme;
