// src/routes/ProtectedRoute.js

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute = ({ children, requiredRole }) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    // You can replace this with a spinner or skeleton component
    return <div>Loading...</div>;
  }

  if (!user) {
    // Redirect unauthenticated users to the login page
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (requiredRole && user.role !== requiredRole) {
    // Optionally, redirect users without the required role
    // You can redirect to a "Not Authorized" page or home
    return <Navigate to="/" replace />;
  }

  // User is authenticated and has the required role (if any)
  return children;
};

export default ProtectedRoute;
