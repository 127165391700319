// src/api/axiosInstance.js

import axios from 'axios';
import { getAccessToken, getRefreshToken, setAccessToken, logoutUser } from '../utils/auth';

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: 'https://djangobackend-b0hpcnepa9epgebc.westus2-01.azurewebsites.net/api', // Replace with your backend URL
    headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
    },
});

// Flag to indicate if the token is being refreshed
let isRefreshing = false;
// Array to hold pending requests while token is refreshed
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

// Request interceptor to attach the access token
axiosInstance.interceptors.request.use(
    config => {
        const token = getAccessToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

// Response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;

        // If error response is 401 and the request hasn't been retried yet
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                // If token is already being refreshed, queue the request
                return new Promise(function(resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                .then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return axiosInstance(originalRequest);
                })
                .catch(err => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const refreshToken = getRefreshToken();
            if (!refreshToken) {
                logoutUser();
                return Promise.reject(error);
            }

            return new Promise(function(resolve, reject) {
                axios.post('https://djangobackend-b0hpcnepa9epgebc.westus2-01.azurewebsites.net/api/token/refresh/', { refresh: refreshToken })
                    .then(({ data }) => {
                        setAccessToken(data.access);
                        axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.access;
                        originalRequest.headers['Authorization'] = 'Bearer ' + data.access;
                        processQueue(null, data.access);
                        resolve(axiosInstance(originalRequest));
                    })
                    .catch((err) => {
                        processQueue(err, null);
                        logoutUser();
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
